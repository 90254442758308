.placeholder {
  background: #cfcfcf;
}

.ui-nestedSortable-error {
  background: #fbe3e4;
  color: #8a1f11;
}

ol.sortable, ol.sortable ol {
  margin: 0 0 0 25px;
  padding: 0;
  list-style-type: none;
}

ol.sortable {
  margin: 10px 0;
}

.sortable li {
  margin: 5px 0 0 0;
  padding: 0;
}

.sortable li div {
  border: 2px solid #999;
  padding: 5px 5px 5px 5px;
  margin: 0;
  cursor: move;
  background: #fff;
}

.sortable li div input {
  margin-top: -4px;
  border: 1px solid #999;
  background: #efefef;
  cursor: pointer;
}

ol.selectable {
  width: 625px;
}

ol.selectable, ol.selectable ol {
  margin: 0 0 0 25px;
  padding: 0;
  list-style-type: none;
}

ol.selectable {
  margin: 7px 0;
}

.selectable li {
  margin: 7px 0 0 0;
  padding: 0;
}

.selectable li div {
  border: 2px solid #999;
  padding: 3px;
  margin: 0;
  background: #fff;
}

.selectmenu {
  border: 2px solid #ccc;
  background: #eee;
  padding: 0px 15px 0px 15px;
  margin-bottom: 0px;
  height: 259px;
  overflow: auto;
}

.sortmenu {
  border: 2px solid #ccc;
  background: #eee;
  padding: 5px 15px 5px 15px;
  margin-bottom: 10px;
}

.ui-state-highlight {
  border-color: $mainColor;
  background: #ccc;
}