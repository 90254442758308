$tabsHoverBorderBottom: $secColor !default;
$tabsActiveBorderBottom: $mainColor !default;
$tabsHeaderBackgroundColor: #EEE !default;
$tabsHeaderLinkColor: #444 !default;

.ui-tabs {
  border: 1px solid #ddd;
  padding: 0;
  border-radius: 0;
  clear: both;

  .ui-tabs-panel {
    padding: 1em 1.5em;

    & > .ui-tabs {
      border-left: 0;
      border-right: 0;
      margin: -1em -1.5em;
    }
  }

  ul.ui-tabs-nav {
    border-radius: 0;
    border: 0 !important;
    padding-left: 0;

    li.ui-tabs-tab {
      border-radius: 0;
      border: 0;
      background: transparent;

      a.ui-tabs-anchor {
        padding: .5em 1em;
        color: $tabsHeaderLinkColor;
        border-bottom: 3px solid $tabsHeaderBackgroundColor;

        &:not(:focus):hover {
          border-bottom: 3px solid $tabsHoverBorderBottom;
        }

        &:focus {
          outline: none;
          -moz-box-shadow: none;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
      }

      &.ui-tabs-active a.ui-tabs-anchor {
        border-bottom: 3px solid $tabsActiveBorderBottom;
      }
    }
  }

  .ui-widget-header {
    background: $tabsHeaderBackgroundColor;
  }
}
