$mainColor: #B10135;
$secColor: #750425;

@import "_backoffice-v2";

#main aside .menu-heading {
  background-image: url("/assets/images/s/backoffice/header-logo.png");
  background-repeat: no-repeat;
  background-position: center center;
  height: 150px;
  background-size: 195px;
}