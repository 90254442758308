.html5MultiFileUploader {
    .filedrag
    {
        display: none;
        font-weight: bold;
        text-align: center;
        padding: 2em 0;
        margin: 1em 0;
        color: #666;
        border: 2px dashed #999;
        border-radius: 7px;
        cursor: default;

        &.hover
        {
            background: #EEE;
        }
    }

    .progress {

        height: auto;

        div
        {
            display: block;
            width: 440px;
            padding: 2px 5px;
            font-size: 90%;
            color: #999;

            .status {
                * {
                    display: none;

                    &:before {
                        content: " - ";
                        color: #999;
                    }
                }

                .failure {
                    color: red;
                }

                .success {
                    color: green;
                }
            }
        }
    }
}