#nonProdInfoHeader {
  background: red;
  color: white;
  position: fixed;
  width: 250px;
  //bottom: 0px;
  //padding: 0px 5px;
  top:0;
  padding:2px 5px;
  font-size: 12px;
  z-index: 100;
}