$headerBackgroundColor: $mainColor;

$headerMenuLinkColor: #fff !default;
$headerMenuLinkHoverBackgroundColor: #fff !default;
$headerMenuLinkHoverColor: $mainColor !default;

$headerSubMenuLinkColor: #777 !default;

$headerSubMenuLinkHoverColor: #333 !default;

$headerSubMenuLinkBackgroundColor: #fff !default;
$headerSubMenuLinkHoverBackgroundColor: #dfdfdf !default;

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  border-color: $headerMenuLinkColor transparent;
}

nav > .menu, nav .main > .menu {
  background: $headerBackgroundColor;
  color: $headerMenuLinkColor;

  & > li {
    margin-left: 2px;
    margin-right: 2px;

    & > a {
      color: $headerMenuLinkColor;
      font-size: 1.2em;
      font-weight: bold;
    }

    &.is-active > a, & > a:hover {
      border-radius: 10px;
      background: $headerMenuLinkHoverBackgroundColor;
      color: $headerMenuLinkHoverColor;

      &::after {
        border-color: $headerMenuLinkHoverColor transparent !important;
      }
    }
  }

  .menu {
    margin-top: 10px;
    z-index: 999;

    a {
      background: $headerSubMenuLinkBackgroundColor;
      color: $headerSubMenuLinkColor;

      &:hover {
        background: $headerSubMenuLinkHoverBackgroundColor;
        color: $headerSubMenuLinkHoverColor;
      }
    }
  }
}