fieldset {
  margin: 20px 0px 10px;
  padding: 0;

  legend {
    margin: 20px 0 10px 0;
    font-weight: bold;
    font-size: 16px;
    color: #666;
  }
}

form, fieldset {
  & > table {
    padding: 0;
    margin: 10px 0;
    width: 100%;

    border: 1px solid $table-border;
    background-color: $table-background;

    &.no-border {
      border: 0;
    }

    th {
      display: block;
      text-align: left;
      line-height: 30px;
      vertical-align: top;
      padding: $table-padding;
      padding-bottom: 0;
      font-weight: bold;
    }

    td {
      display: block;
      padding: $table-padding;
      padding-top: 0;
      line-height: 30px;
    }
  }

  em {
    color: #ccc;
  }

  tbody tr:nth-child(even) em {
    color: #999;
  }
}

@include breakpoint(medium) {
  form, fieldset {
    & > table {
      th {
        font-weight: normal;
        width: 20%;
        display: table-cell;
        padding: $table-padding;
      }

      td {
        display: table-cell;
        padding: $table-padding;
      }
    }
  }
}

input:not([type=submit]):not([type=reset]):not([type=file]):not([type=button]):not([type=checkbox]) {
  border-radius: 2px;
  padding: 0 10px;
  min-width: 200px;
  box-sizing: border-box;
  height: 28px;
  line-height: 26px;
  margin: 0;
  display: inline-block;
}

input {
  .uppercase {
    text-transform: uppercase;
  }
}

textarea {
  padding: 0 10px;
  border-radius: 2px;
  box-sizing: border-box;
  min-width: 200px;
  height: 200px;
  line-height: 26px;
}

select {
  //background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIwLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0LjkgMTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQuOSAxMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM0NDQ0NDQ7fQo8L3N0eWxlPgo8dGl0bGU+YXJyb3dzPC90aXRsZT4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxLjQsNC43IDIuNSwzLjIgMy41LDQuNyAiLz4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIzLjUsNS4zIDIuNSw2LjggMS40LDUuMyAiLz4KPC9zdmc+Cg==) no-repeat right 1px center;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
  background-repeat: no-repeat;
  background-position: right -1rem center;
  background-size: auto 22px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0 20px 0 10px;
  padding-right: 20px !important;

  box-sizing: border-box;
  min-width: 200px;
  border-radius: 2px;
  height: 28px;
  line-height: 26px;

  border-radius: 2px;
  margin: 0;
  display: inline-block;
}

select[multiple] {
  height: auto;
  background-image: unset;
  background-repeat: unset;
  background-position: unset;
  background-size: unset;
  padding-right: 20px !important;
}

input[type=checkbox], input[type=radio] {
  width: initial !important;
  min-width: initial !important;
  height: initial !important;
  margin: 0px 2px 0px 2px;
}

.hasDatepicker {
  background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+) no-repeat right -2px center;
  background-size: auto 22px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0 20px 0 10px;
  padding-right: 20px !important;
}

input:not([type=submit]):not([type=reset]):not([type=file]):not([type=button]), select, textarea {
  border: 1px solid #bbb;
  background-color: #fff;
  color: #666;
}

input:not([type=submit]):not([type=reset]):not([type=file]):not([type=button]):focus, select:focus, textarea:focus {
  outline: none;
  box-shadow: 0 0 10px #bbb;
}

option {
  color: #666;
  border-color: #bbb;
}

@include breakpoint(medium) {
  input:not([type=submit]):not([type=reset]):not([type=file]):not([type=button]):not([type=checkbox]), textarea, select {
    width: 60%;
  }
}