.meter { 
    height: 20px;  /* Can be anything */
    position: relative;
    background: #555;
    border-radius: 25px;
    padding: 10px;
    box-shadow        : inset 0 -1px 1px rgba(255,255,255,0.3);
}
.meter > span {
    display: block;
    height: 100%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: rgb(43,194,83);
    background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(0, rgb(43,194,83)),
        color-stop(1, rgb(84,240,84))
        );
    background-image: -moz-linear-gradient(
        center bottom,
        rgb(43,194,83) 37%,
        rgb(84,240,84) 69%
        );
    box-shadow: 
        inset 0 2px 9px  rgba(255,255,255,0.3),
        inset 0 -2px 6px rgba(0,0,0,0.4);
    position: relative;
    overflow: hidden;
}
.meter > span:after, .animate > span > span {
    content: "";
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    background-image: 
        -webkit-gradient(linear, 0 0, 100% 100%, 
        color-stop(.25, rgba(255, 255, 255, .2)), 
        color-stop(.25, transparent), color-stop(.5, transparent), 
        color-stop(.5, rgba(255, 255, 255, .2)), 
        color-stop(.75, rgba(255, 255, 255, .2)), 
        color-stop(.75, transparent), to(transparent)
        );
    background-image: 
        -moz-linear-gradient(
        -45deg, 
        rgba(255, 255, 255, .2) 25%, 
        transparent 25%, 
        transparent 50%, 
        rgba(255, 255, 255, .2) 50%, 
        rgba(255, 255, 255, .2) 75%, 
        transparent 75%, 
        transparent
        );
    z-index: 1;
    -webkit-background-size: 50px 50px;
    -moz-background-size: 50px 50px;
    -webkit-animation: move 2s linear infinite;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
}

.animate > span:after {
    display: none;
}

@-webkit-keyframes move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 50px 50px;
    }
}

.meter.orange > span {
    background-color: #f1a165;
    background: linear-gradient(#f1a165, #f36d0a);
}

.meter.red > span {
    background-color: #f0a3a3;
    background: linear-gradient(#f0a3a3, #f42323);
}

.meter.blue > span {
    background-color: #a3caf0;
    background: linear-gradient(#a3caf0, #238cf4);
}

.meter.nostripes > span > span, .meter.nostripes > span:after {
    -webkit-animation: none;
    background-image: none;
}

.meter.small {
    height: 5px;
    padding: 0;
}