#lean_overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000;
  display: none;
}

.popupHeader {
  .modal_close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px 15px;
    background: #E4E4E2;
    cursor: pointer;
    color: #aaa;
    font-size: 18px;
  }
}