.vertTable {
  border: 1px solid $table-border;
  padding: 0px;
  width: 100%;
  line-height: 22px;
  margin: 1rem 0;
  background-color: $table-background;

  &.no-border {
    border: 0;
  }

  th {
    display: block;
    text-align: left;
    padding: $table-padding;
    padding-bottom: 0;
    vertical-align: top;
    font-weight: bold;
  }

  td {
    display: block;
    padding: $table-padding;
    padding-top: 0;

    textarea {
      margin: 0;
      padding: 0 3px;
    }

    input:not([type=submit]):not([type=reset]):not([type=file]):not([type=button]):not([type=checkbox]), select, .button {
      margin: 0;
      padding: 0 3px;
      line-height: 20px;
      height: 22px;
      width: auto;
    }
  }
}

@include breakpoint(medium) {
  .vertTable {
    th {
      font-weight: normal;
      width: 20%;
      display: table-cell;
      border-right: 2px solid $mainColor;
      padding: $table-padding;
    }

    td {
      display: table-cell;
      padding: $table-padding;
    }
  }
}

.horTable {
  border: 1px solid $table-border;
  width: 100%;
  line-height: 22px;
  padding: 0px;
  margin: 1rem 0;
  background-color: $table-background;

  &.no-border {
    border: 0;
  }

  td {
    padding: $table-padding;

    textarea {
      margin: 0;
      padding: 0 3px;
    }

    input:not([type=submit]):not([type=reset]):not([type=file]):not([type=button]):not([type=checkbox]), select, .button {
      margin: 0;
      padding: 0 3px;
      line-height: 20px;
      height: 22px;
    }
  }

  th {
    padding: $table-padding;
    text-align: left;
    border-bottom: 2px solid $mainColor;
    font-weight: bold;
  }

  tfoot th {
    padding: 2px;
    text-align: left;
    border: 0px;
    background: #ccc;
    color: #333;
    font-weight: normal;
    font-style: italic;
  }
}

table.no-border {
  border: 0;
}