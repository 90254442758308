.handleiding {
  object {
    border: 5px solid #ccc;
    width: 750px;
    height: 452px;

    width: 650px;
    height: 395px;
  }

  table {
    width: 100%;

    tr td:first-child {
      width: 100px;
    }
  }
}
