$headerBackgroundLogo: $mainColor !default;
@import "helpers/all";
@import "../components/sprites";
@import "../components/filedrag";
@import "../components/progressBars";
@import "../components/nonProdInfoHeader";
@import "../components/sortAndSelectable";
@import "../components/flashdata";
@import "../../../vendor/multoo/foundation/sass/backoffice/main";
@import "themes/backoffice-v2";
@import "../components/mediaLib";
@import "../components/extraDeveloperInfo";
@import "../vendor/leanModal/main";

@import url("https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/themes/smoothness/jquery-ui.min.css");
@import url("https://cdn.datatables.net/1.10.20/css/dataTables.foundation.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/datatables-colvis/1.1.2/css/dataTables.colVis.min.css");
@import url("https://cdn.datatables.net/buttons/1.6.1/css/buttons.foundation.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/toastr.js/2.1.4/toastr.min.css");

.ui-widget-content .button {
  color: #efefef;
}

.ui-widget-content .buttonGroupRightTop .button {
  color: #0a0a0a;
}

img {
  max-width: none;
}

#main #content a:not(.button), .reveal a:not(.button) {
  color: #0000EE;
  text-decoration: underline;
}

#main #content #kpiDashboard tbody {
  td:first-child {
    width: 150px;
    text-align: center;
    white-space: nowrap;
  }

  td:last-child {
    text-align: left;
    white-space: nowrap;
  }
}

#main #content .ui-tabs ul.ui-tabs-nav li.ui-tabs-tab a.ui-tabs-anchor {
  text-decoration: none;
}

@include breakpoint(small only) {
  #main #content fieldset legend.filterToggle {
    @include button(true);
    font-weight: normal;

    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    line-height: 16px;
    padding: .5em 1em;
    cursor: pointer;

    em {
      color: $button-color;
    }
  }
}
