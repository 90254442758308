html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

// Unlike Eric's original reset, we reset the html element to be compatible
// with the vertical rhythm mixins.
html {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
caption, th, td {
    text-align: left;
    font-weight: normal;
    vertical-align: middle;
}
q, blockquote {
    quotes: none;
    &:before, &:after {
        content: "";
        content: none;
    }
}
a img {
    border: none;
}

//#{elements-of-type(html5-block)} {
//    display: block;
//}

b, strong {
    font-weight: bold;
}

em {
    font-style: italic;
}