@include add-foundation-colors;

.dataTables_filter label input {
  margin-left: 10px !important;
}

table.dataTable {
  border: 1px solid $table-border;
  background-color: $table-background;
  margin: 0 !important;

  &.no-border {
    border: 0;
  }

  thead {
    th {
      //padding: 7px 18px 7px 5px;
      padding: $table-padding;
      padding-right: 18px;
      width: auto;
      min-width: initial;
      border-bottom: 2px solid $mainColor;
      font-weight: bold;
      cursor: pointer;
      *cursor: hand;
    }

    th.noSort {
      //padding: 8px 5px 8px 5px;
      padding: $table-padding;
      border-bottom: 2px solid $mainColor;
      font-weight: bold;
      cursor: default;
      background: none !important;
    }
  }

  tfoot th {
    //padding: 7px 18px 7px 5px !important;
    padding: $table-padding;
    width: auto;
    min-width: initial;
  }

  tbody td {
    //padding: 5px 8px 5px 5px !important;
    padding: $table-padding;
    padding-right: 18px;
    line-height: 21px;

    input:not([type=submit]):not([type=reset]):not([type=file]):not([type=button]):not([type=checkbox]), select, .button {
      margin: 0;
      padding: 2px 5px;
      width: auto;
    }

    img {
      max-width: initial;
    }
  }

  tr.odd {
    background: #fff;
  }

  tr.even {
    background: #eee;
  }

  tr.odd td.sorting_1 {
    background: #e5e5e5;
  }

  tr.odd td.sorting_2 {
    background: #e7e7e7;
  }

  tr.odd td.sorting_3 {
    background: #e9e9e9;
  }

  tr.even td.sorting_1 {
    background: #f0f0f0;
  }

  tr.even td.sorting_2 {
    background: #f2f2f2;
  }

  tr.even td.sorting_3 {
    background: #f4f4f4;
  }

  .no-footer {
    border-color: #999;
  }

  tfoot {
    select {
      min-width: auto;
    }
  }
}

.dt-buttons {
  margin: 9px;
  float: right;

  .button {
    padding: 0.1875rem 0.625rem;
    line-height: inherit;
  }
}

.dataTables_info {
  margin: 10px 25px;
  float: left;
}

.dataTableOverflowContainer {
  overflow-x: auto;
  width: 100%;
  border: 1px solid $table-border;

  table.dataTable {
    border: 0;
  }
}

.ColVis {
  margin: 0 0 0 10px;

  div.ColVis {
    margin-bottom: 0;

    button {
      margin-top: 1px;
    }
  }

  button.ColVis_Button, button.ColVis_Button:hover {
    height: auto;
    font-size: 0.88em;
    line-height: 150%;
    color: color-pick-contrast($secondary-color, ($button-color, $button-color-alt));
    background: $secondary-color;
    font-family: inherit;
    font-size: 100%;
    padding: .5em 1em;
    color: #444;
    color: rgba(0, 0, 0, .8);
    border: 1px solid #999;
    border: transparent;
    background-color: #E6E6E6;
    text-decoration: none;
    border-radius: 2px;
    display: inline-block;
    zoom: 1;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    line-height: 16px;
    margin: 5px;
    margin-right: 0px;
    box-shadow: none;
    @include button(false, $secondary-color, scale-color($secondary-color, $lightness: -15%), color-pick-contrast($secondary-color, ($button-color, $button-color-alt)));
    padding: 0.1875rem 0.625rem;
    line-height: inherit;
    height: 28px;
  }
}

ul.ColVis_collection {
  width: 250px;

  background: #fff;
  border-radius: 0;

  label > [type='checkbox'] {
    margin-bottom: 0;
  }

  li {
    padding: 0.25em 1em;
    box-shadow: none;
    background: #eee;
    margin-right: 0;
    overflow: hidden;
  }

  margin-top: 7px;
  margin-left: -25px;
}