#mLibImages {
    * {
        box-sizing: border-box;
    }

    padding:5px 0 5px 5px;

    .objectContainer {
        float:left; 
        width:118px; 
        height:138px; 
        overflow:hidden; 
        margin:3px; 
        padding:3px 3px 3px 3px; 
        border:1px solid #c5c5c5; 
        background:#fff;
        border-radius: 4px;
        position:relative;
        .image{
            position:relative;
            width:110px;
            height:110px;
            img {
                max-width:110px; 
                max-height:110px; 
                position:absolute;
                top:0;
                bottom:0;
                left:0;
                right:0;
                margin:auto;
            }
        }

        .name {
            border-top: 1px solid #c5c5c5;
            height:20px;
            background:#ddd;
            bottom: 0px;
            position:absolute;
            left:0;
            right: 0;
            overflow: hidden;
            padding:0 3px;

            span {
                position:absolute;
                top:0;
                display: inline-block;
                line-height:20px;
                font-size:12px;
                white-space:nowrap;
            }
        }

        .remove {
            position:absolute;
            right: 0;
            z-index:10;
            bottom:-1px;
        }
    }
}