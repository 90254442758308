$mainColor: #000 !default;
$secColor: #333 !default;

$fd-primary-color: $mainColor !default;
$fd-secondary-color: #eee !default;
$fd-success-color: #3adb76 !default;
$fd-warning-color: #ffae00 !default;
$fd-alert-color: #cc4b37 !default;

$content-link-color: $mainColor !default;

$table-padding: 5px !default;
$table-border: #999 !default;
$table-background: #fff !default;
$table-striped-background: #eee !default;

$dropdownmenu-submenu-background: #fff !default;

$foundation-palette: (
        primary: $fd-primary-color,
        secondary: $fd-secondary-color,
        success: $fd-success-color,
        warning: $fd-warning-color,
        alert: $fd-alert-color
);

@import "~foundation-sites/scss/foundation";

@include foundation-everything;

body {
  font-family: Arial;
  font-size: 14px;
  line-height: 1.5em;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  background: #666;
}

ol {
  list-style-type: decimal;
  margin: 10px 25px;
}

ul {
  list-style-type: disc;
  margin: 10px 25px;
}

input, textarea, select, option, [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'] {
  font-family: Arial;
  font-size: 14px;
  vertical-align: middle;
}

[type='file'] {
  margin: 0;
}

p {
  margin: 10px 0px;
}

header.top-bar {
  min-height: 60px;
  background: $headerBackgroundLogo;

  .title-bar-title {
    color: #fff;
    font-size: 24px;
  }

  .title-bar {
    background: $headerBackgroundLogo;
  }

  @import "components/header-menu";
}

#main {
  min-height: 75vh;
  background: #fff;

  aside {
    background-color: #efefef;
    box-shadow: inset -2px 0 0 #cfcfcf;

    min-height: 100%;
    position: absolute;
    z-index: 100;

    @import "components/aside-menu";

    .menu-heading {
      padding: 1em;

      a {
        text-decoration: none;
        color: #565d64;
      }
    }
  }

  #content {
    background: #fff;
    padding: 1.5em;

    h1, h2, h3, h4, h5, h6 {
      font-weight: bold;
      font-size: 24px;
      color: $secColor;
      margin: 10px 0px;
    }

    h2 {
      font-size: 20px;
    }

    h3, h4, h5, h6 {
      font-size: 16px;
      color: #666;
    }

    a:not(.button) {
      color: $content-link-color;
    }

    @import "components/form";
    @import "components/table";
    @import "components/tabs";
  }
}

@import "components/dataTable";

@include breakpoint(medium) {
  #main {
    aside {
      min-height: initial;
      position: static;
      min-width: 200px;
      max-width: 300px;
    }

    aside, #content {
      padding-bottom: 100px;
    }
  }
}

footer {
  clear: both;
  padding: 20px 0;
  color: #999;
  background: #333;

  a {
    color: #666;
  }

  text-align: center;


}

@import "components/button";

#devData {
  text-align: left;
  width: initial;
  border: 0;
  font-size: 12px;
  line-height: 14px;
  background: #fff;
  margin-top: 350px;
  color: #999;
  box-sizing: border-box;

  & > .toggleButton {
    color: #ccc;
  }

  & > .data {
    padding: 20px 0;
    display: none;
  }
}

.ui-widget, .ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
  font-family: Arial;
  font-size: 14px;
  vertical-align: middle;
}

.reveal {
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    font-size: 24px;
    color: $secColor;
    margin: 10px 0px;
  }

  h2 {
    font-size: 20px;
  }

  h3, h4, h5, h6 {
    font-size: 16px;
    color: #666;
  }

  table.horTable {
    border: 1px solid $table-border;

    th {
      border-bottom: 2px solid $mainColor;
    }

    [type="checkbox"], [type="radio"] {
      margin: 0;
    }
  }
}

