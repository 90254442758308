@include add-foundation-colors;

.buttonGroupRightTop {
  float: right;
  margin-bottom: 10px;

  .button {
    @include button(false, $secondary-color, scale-color($secondary-color, $lightness: -15%), color-pick-contrast($secondary-color, ($button-color, $button-color-alt)));
    margin:1px;
  }
}

input[type=submit] {
  @include button(false, $primary-color, scale-color($primary-color, $lightness: -15%), color-pick-contrast($primary-color, ($button-color, $button-color-alt)));
}

input[type=reset] {
  @include button(false, $secondary-color, scale-color($secondary-color, $lightness: -15%), color-pick-contrast($secondary-color, ($button-color, $button-color-alt)));
}