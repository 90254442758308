$sideBarMenuLinkColor: #777 !default;
$sideBarSubMenuLinkColor: #777 !default;

$sideBarMenuHoverBackgroundColor: #cfcfcf !default;
$sideBarSubMenuHoverBackgroundColor: #cfcfcf !default;

$sideBarSubMenuParentHoverBackgroundColor: #dfdfdf !default;

$sidebarSelectedBackgroundColor: #fff !default;
$sidebarSelectedBorderColor: $secColor !default;
$sidebarSelectedLinkColor: #000 !default;

.dropdown .selected {
  background: $sidebarSelectedBackgroundColor;
  box-shadow: inset -2px 0 0 #cfcfcf;

  & > a {
    border-left: 5px solid $sidebarSelectedBorderColor;
    color: $sidebarSelectedLinkColor;

    padding: 0.7rem 1rem 0.7rem .5rem;
  }

  &.is-dropdown-submenu-parent > a::after {
    @include css-triangle($dropdownmenu-arrow-size, $dropdownmenu-arrow-color, down);
    margin-top: -4px !important;
    margin-right: -2px !important;
  }

  & > .submenu {
    display: block;
    position: initial;
    border: 0;
    box-shadow: inset -2px 0 0 #cfcfcf;
    border-left: 5px solid $sidebarSelectedBorderColor;

    & > li > a {
      color: $sidebarSelectedLinkColor;
      padding: 0.7rem 1rem 0.7rem 1rem;
    }
  }
}

.dropdown.menu.vertical > li.opens-right > a::after {
  border-color: transparent $sideBarMenuLinkColor;
}

.dropdown.menu.vertical > li.selected > a::after {
  border-color: $sideBarMenuLinkColor transparent;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  margin-top: -6px;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
  border-color: transparent $sideBarSubMenuLinkColor;
}

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
  border-color: $sideBarMenuLinkColor transparent !important;
}

a {
  color: $sideBarMenuLinkColor;
}

.submenu a {
  color: $sideBarSubMenuLinkColor;
}

.menu .is-active > a {
  background: $sideBarSubMenuParentHoverBackgroundColor;
}

.menu li:hover {

  & > a {
    background: $sideBarMenuHoverBackgroundColor;
  }

  .is-dropdown-submenu-item:hover > a {
    background: $sideBarSubMenuParentHoverBackgroundColor;
  }

  .is-dropdown-submenu-parent:hover > a {
    background: $sideBarSubMenuParentHoverBackgroundColor;
  }
}