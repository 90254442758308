.sprButton {
  img {
    margin-right: 5px;
  }
}

img.sprBlue {
  background-image: url('/assets/images/sprites.png');
  height: 20px;
  width: 20px;
  background-position: -0px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprDatepicker {
  background-image: url('/assets/images/sprites.png');
  height: 17px;
  width: 17px;
  background-position: -20px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprDetail {
  background-image: url('/assets/images/sprites.png');
  height: 16px;
  width: 16px;
  background-position: -37px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprAdd {
  background-image: url('/assets/images/sprites.png');
  height: 16px;
  width: 16px;
  background-position: -53px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprDownload {
  background-image: url('/assets/images/sprites.png');
  height: 16px;
  width: 16px;
  background-position: -69px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprEdit {
  background-image: url('/assets/images/sprites.png');
  height: 16px;
  width: 16px;
  background-position: -85px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprEditDisabled {
  background-image: url('/assets/images/sprites.png');
  height: 16px;
  width: 16px;
  background-position: -101px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprEmail {
  background-image: url('/assets/images/sprites.png');
  height: 16px;
  width: 16px;
  background-position: -117px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprGreen {
  background-image: url('/assets/images/sprites.png');
  height: 20px;
  width: 20px;
  background-position: -133px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprInfo {
  background-image: url('/assets/images/sprites.png');
  height: 16px;
  width: 16px;
  background-position: -153px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprLock {
  background-image: url('/assets/images/sprites.png');
  height: 16px;
  width: 16px;
  background-position: -169px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprOrange {
  background-image: url('/assets/images/sprites.png');
  height: 20px;
  width: 20px;
  background-position: -185px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprPrint {
  background-image: url('/assets/images/sprites.png');
  height: 16px;
  width: 16px;
  background-position: -205px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprRed {
  background-image: url('/assets/images/sprites.png');
  height: 20px;
  width: 20px;
  background-position: -221px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprRemove {
  background-image: url('/assets/images/sprites.png');
  height: 16px;
  width: 16px;
  background-position: -241px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprRemoveDisabled {
  background-image: url('/assets/images/sprites.png');
  height: 16px;
  width: 16px;
  background-position: -257px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprUnlock {
  background-image: url('/assets/images/sprites.png');
  height: 16px;
  width: 16px;
  background-position: -273px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprXml {
  background-image: url('/assets/images/sprites.png');
  height: 16px;
  width: 16px;
  background-position: -289px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprGrey {
  background-image: url('/assets/images/sprites.png');
  height: 20px;
  width: 20px;
  background-position: -305px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprWeb {
  background-image: url('/assets/images/sprites.png');
  height: 16px;
  width: 16px;
  background-position: -325px -0px;
  vertical-align: middle;
  margin-top: -3px;
}

img.sprPdf {
  background-image: url('/assets/images/sprites.png');
  height: 16px;
  width: 16px;
  background-position: -342px -0px;
  vertical-align: middle;
  margin-top: -3px;
}
