.flashdata {
  padding: 15px;
  margin: 10px 0;
  border-radius: 4px;

  &.info {
    color: #31708f;
    background: #d9edf7;
    border: 1px solid #bce8f1;
  }

  &.success {
    color: #3c763d;
    background: #dff0d8;
    border: 1px solid #d6e9c6
  }

  &.warn {
    color: #8a6d3b;
    background: #fcf8e3;
    border: 1px solid #faebcc;
  }

  &.error {
    color: #a94442;
    background: #f2dede;
    border: 1px solid #ebccd1;
  }

  a.close {
    position: relative;
    color: inherit;
    text-decoration: none !important;
    font-size: 30px !important;
    line-height: .7;
    float: right;
    opacity: .2;
    text-shadow: 0 1px 0 #fff;
  }

  p {
    font-size: 15px;
    margin: 0;
  }
}