#headerTitle {
  color: #fff;
  font-size: 24px;
  position: absolute;
  left: 10px;
  top: 15px;
}

@media (max-width: 68em) {
  #headerTitle {
    left: 60px;
  }
}

@import "../pages/manual";